export default {
  liveChatAddressOCSS: {
    propertyStr: "",
    url: "",
    state: false,
  },
  activeIndexTab: {}, 
  test: {}, 
  token: "",
  u2token: "",
  interval: "",
  smallImages: [], 
  loadingState: true,
  showDetail: false, 
};
