
export default {
    loadJS(src, isAsync, id) {
        // 通用加载js函数
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        id && (script.id = id);
        isAsync && script.setAttribute('async', isAsync);
        document.body.appendChild(script);
      
    },
};
