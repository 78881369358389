<template>
  <div class="detail-container opacity-in">
    <div class="box-cont" :style="boxSize + 10">
      <div class="return" @click="$emit('back')">
        <img src="../../../assets/Images/arrow.png" alt="" />
        返回
      </div>
      <div class="box">
        <div class="title">{{ image.title }}</div>
        <div class="time">{{ newDate }}</div>
        <div class="img">
          <img :src="image.pic" alt="" ref="img" />
        </div>
        <div class="description" :style="boxSize">
          {{ image.describe }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
export default {
  props: ["image"],
  setup(props) {
    //refs
    const image = ref(props.image);
    const desc = ref(null);
    const boxSize = ref({});
    const img = ref(null);
    const newDate = ref();
    onMounted(() => {
      boxSize.value = {
        width: img.value.clientWidth + "px",
      };
      const date = new Date(image.value.time_begin * 1000);
      const min =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      const hour =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      const sec =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      newDate.value =
        date.getFullYear() +
        "年" +
        (date.getMonth() + 1) +
        "月" +
        date.getDate() +
        "日 " +
        hour +
        ":" +
        min +
        ":" +
        sec;
    });
    //------///

    return {
      image,
      boxSize,
      img,
      newDate,
    };
  },
};
</script>

<style lang="scss" scoped>
.detail-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 860px;
  flex-direction: column;
  gap: 5px;
  background: #f2f4f6;
  position: absolute;
  width: 100vw;
  top: 8.5vh;
  z-index: 9;
  .box-cont {
    position: relative;
    .return {
      position: absolute;
      top: -9px;
      left: 0;
      background: #fff;
      color: var(--font-gray);
      height: 40px;
      width: 106px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      gap: 7px;
      z-index: 5;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.1);
      }
      &:active {
        scale: 0.8;
      }
    }
    .box {
      position: relative;
      background: #fff;
      padding: 20px;
      height: 785px;
      overflow-y: auto;
      margin-top: 40px;
      &::-webkit-scrollbar {
        width: 15px;
      }
      &::-webkit-scrollbar-track-piece {
        width: 100px;
        margin: 0 700px;
        padding: 0;
        background: transparent;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        width: 50px;
        border-radius: 10px;
        background: #e4e7eb;
      }
      .title {
        color: var(--main-brown);
        font-size: 36px;
      }
      .time {
        color: var(--font-gray);
        font-size: 14px;
      }
      .img {
        margin-top: 10px;
      }
      .description {
        margin-top: 10px;
        color: #151515;
        font-size: 16px;
        overflow-wrap: break-word;
      }
    }
  }
}
</style>
