import axios from 'axios';
import md5 from 'md5';
import axiosConfig from '@/config/config';
import {store} from '@/store/index';
import business from '@/store/business';
// import { Message } from 'element-ui'

const isDev = process.env.NODE_ENV === 'development';
const baseURL = (isDev ? axiosConfig.apiUrl : '') + '/_glaxy_1e3c3b_';
const service = axios.create({
    baseURL: baseURL,
    timeout: 30 * 1000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
});
async function assembleParam(config) {
  
    const qid = getQid();
    let params = ''; //保留参数
    if (config.headers['Content-Type'] == 'multipart/form-data') {
        // FormData
        if (!config.data) config.data = new FormData();
        config.data.append('productId', axiosConfig.PRODUCT_ID);
        if (store.state.loginStatus && !config.data.get('loginName')) {
            config.data.append('loginName', store.state.userName);
        }
        let form_data = {};
        for (let pair of config.data.entries()) {
            if (typeof pair[1] == 'string') {
                form_data[pair[0]] = pair[1];
            }
        }
        params = form_data;
    } else {
        // JSON
        config.data || (config.data = {});
        config.data['productId'] = axiosConfig.PRODUCT_ID;
        if (store.state.loginStatus && !config.data['loginName']) {
            config.data['loginName'] = store.state.userName;
        }
        params = config.data;
    }
    Object.assign(config, {
        headers: {
            deviceId: localStorage.getItem('deviceId'),
            v: axiosConfig.HEADER_V,
            appId: axiosConfig.APP_ID,
            token: store.state.token,
            qid: qid,
            sign: getSign(params, qid),
            domainName: location.hostname,
        },
    });
    /**
     * 如果没有token，则等待token获取成功再调取该接口
     * 调用vue生命周期控制 in /App.vue
     */
    // 设置部分传递到存款小助手参数
    // window.iframData = {};
    // window.iframData.token = store.state.token;
    // window.iframData.deviceId = store.state.deviceId;
    // window.iframData.appKey = axiosConfig.APP_ID;
    // window.iframData.platformVersion = axiosConfig.HEADER_V;
    return config;
}

service.interceptors.request.use(
    config => {
        return Promise.resolve(Object.assign(config, assembleParam(config)));
    },
    error => {
        Promise.reject(error);
    },
);

service.interceptors.response.use(
    response => {
        if (response.data.head.errCode == '0000') {
            return Promise.resolve({
                status: true,
                head: response.data.head,
                data: response.data.body, //兼容旧代码
                body: response.data.body,
            });
        } else {
            alertErrorInfo(response);
            return Promise.resolve({
                status: false,
                head: response.data.head,
                data: response.data.head, //兼容旧代码
                body: response.data.body || '',
            });
        }
    },
    error => {
        return {
            status: false,
        };
    },
);

/**
 * qid: 唯一请求 ID, 建议生成规则 MD5(毫秒时间戳 + 随机 6 位数)
 */
function getQid() {
    // 获取随机生成的qid
    return md5(new Date().getTime() + (100000 + Math.floor(Math.random() * 900000)).toString());
}

/**
 * qid: 唯一请求 ID, 建议生成规则 MD5(毫秒时间戳 + 随机 6 位数)
 */
function getSign(data, qid) {
    // 获取加密签名
    return md5(
        JSON.stringify(data)
            .split('')
            .sort()
            .reverse()
            .join('') +
            qid +
            axiosConfig.APP_ID +
            axiosConfig.HEADER_V +
            (store.state.token ? store.state.token + store.state.u2token : ''),
    );
}

/**
 * head: 接口返回的含有错误信息的head
 */
function alertErrorInfo(response) {
    // 所有接口的根据错误code弹出前端自定义信息或后台返回信息
    let errMsg = '';
    const { head, body } = response.data;
    let data = {}; // 传参cancelErrMsg取消msg的提示信息
    if (typeof response.config.data == 'string') {
        data = JSON.parse(response.config.data);
    } else {
        data = response.config.data;
    }

    switch (head.errCode) {
        // 需要刷新页面的后台报错
        case 'GW_890206': // 您长时间未操作，请重新登录
            errMsg = '由于您长时间未操作，请重新登录';
        case 'GW_890209': // 校验token异常
            head.errMsg = ''; // 异常不弹
            setTimeout(() => {
                window.localStorage.removeItem('vuex');
                window.localStorage.removeItem('info');
                window.localStorage.removeItem('isKeyAccount');
                window.localStorage.removeItem('agLineConfig');
                window.sessionStorage.clear();
                window.document.cookie = '';
                window.location.reload();
            }, 2000);
            break;
        case 'GW_899998': // IP限制错误码、地域限制错误码、域名限制错误码
        case '899998':
            window.location.replace('/visitwarn.htm');
            break;
        case 'GW_890201':
            errMsg = '验证码不能为空';
            break;
        // case 'WS_202020': // 会员锁定code
        //     errMsg = {
        //         title: '温馨提示',
        //         message: head.errMsg,
        //         buttons: [
        //             {
        //                 name: '五分钟再试',
        //                 callback: function() {
        //                     business.hideAlert();
        //                 },
        //             },
        //             {
        //                 name: '立即解锁',
        //                 callback: function() {
        //                     business.hideAlert();
        //                     store.commit('SET_SHOWLOCKPOP', true);
        //                 },
        //             },
        //         ],
        //     };
        //     break;
        case 'GW_800510':
            store.commit('SET_SAMEPHONELOGINNAMESINFO', body);
            errMsg = '该手机号绑定了多个账号，请选择对应账号进行登录';
            break;
        // 不需要弹出的后台接口报错
        // case 'GW_801607': // 该游戏平台目前维护中
        case 'GWX_999999': // loginName是必要的参数
        case 'GW_890401': // 用户名不能为空
        case 'GW_801102': // 调用支付系统返回错误码(BQ支付未配置银行)
        case 'GW_800505': // 该会员未绑定手机(忘记密码)
        case 'WS_202335': // 登录名不能为空!
            head.errMsg = '';
            break;
        case 'GW_800503': // 该手机号码无法注册或绑定，请更换其他号码或联系客服！ 字太长了中间做换行
            let msgArr = head.errMsg.split('，');
            if (msgArr.length > 1) head.errMsg = `${msgArr[0]}，<br>${msgArr[1]}`;
            break;
        case 'GW_800705': // 存款时,姓名与绑定的姓名不相同!
            head.errMsg = ''; //弹自定义对话框
            break;
        case 'GW_601640': // 弹窗 为了您的资金安全，请您联系客服新增取款钱包
            head.errMsg = ''; //弹自定义对话框
            break;
        default:
            break;
    }
    // data.cancelErrMsg || ((errMsg || head.errMsg) && business.showAlert(errMsg || head.errMsg));
}

export default service;
