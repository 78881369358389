<template>
  <div class="main-container">
    <NavBar />
    <div class="kefu">
      <img src="../assets/Images/kefu.png" alt="" @click="goOnlineCS" />
    </div>
    <router-view />
  </div>
</template>

<script>
import NavBar from "@/components/nav_bar";
import { useStore } from "vuex";
import { ref } from "@vue/reactivity";
import { computed } from '@vue/runtime-core';
export default {
  setup() {
    //ref
    const store = useStore();
    //computed
    const liveChatOCSSMode = ref(
      computed(() => store.state.liveChatAddressOCSS.state)
    );

    //methods
    const goOnlineCS = () => {
      if (liveChatOCSSMode.value) {
        cskefu.openChatDialog();
      }
    };

    return {
      goOnlineCS,
    };
  },
  components: {
    NavBar,
  },
};
</script>

<style>
@import url("../assets/sass/index.scss");
</style>
