<template>
  <div class="topic-container">
    <div class="_swiper_contain">
      <swiper
        :options="swiperOption"
        class="swiper"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide v-for="(item, index) in flatSlides" :key="index">
          <div
            class="img-slide"
            :style="{ backgroundImage: 'url(' + item.pic + ')' }"
          ></div>
          <div class="desc">
            <div class="title">{{ item.title }}</div>
            <div class="description">
              {{ item.describe }}
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="paginations">
      <div class="prev btn" @click="slider.slidePrev()">
        <img src="../../assets/Images/left.svg" alt="" />
      </div>
      <div class="pagination-container" ref="pagination">
        <div class="pagination" :style="translateX" ref="pag">
          <div class="item" v-for="(item, index) in topicData" :key="index">
            <div class="year">{{ item.year }}</div>
            <div
              class="point-item"
              v-for="(item, index) in item.slides"
              :key="index"
            >
              <div
                class="decription"
                @click="slider.slideTo(item.tab_index)"
                :class="{ active: item.tab_index + 1 == activeTab }"
              >
                {{ item.title }}
                <div
                  class="bullet"
                  :class="{ active: item.tab_index + 1 == activeTab }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="next btn" @click="slider.slideNext()">
        <img src="../../assets/Images/right.svg" alt="" />
      </div>
    </div>
    <Loader v-if="isLoad" />
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import axios from "axios";
import Loader from "@/components/loader/index.vue";

export default {
  setup() {
    const slider = ref();
    const swiperOption = ref({
      slidesPerView: "1",
    });
    const flatSlides = ref([]);
    const slides = ref([]);
    const activeTab = ref(1);
    const translateX = ref({});
    const pag = ref();
    const pagination = ref();
    const isLoad = ref(false);
    const topicData = ref([]);
    //----//
    //mounts
    onMounted(() => {
      getImages();
    });

    //methods
    const onSwiper = (swiper) => {
      slider.value = swiper;
    };
    const getImages = async () => {
      isLoad.value = true;
      try {
        const wmsPath =
          location.origin.includes("a01-brandsite.k8s-fat") ||
          location.origin.includes("localhost")
            ? "_t"
            : "_l";
        const response = await axios.get(
          `cdn/1e3c3bPB/externals/static/_wms/${wmsPath}/_data/_promo/topic_data.txt?` +
            Math.floor(Date.now() / 1000)
        );
        if (response) {
          isLoad.value = false;
          const topic_data = response.data;
          const grouped = topic_data.reduce((group, product) => {
            const { time_begin } = product;
            const date = new Date(time_begin * 1000);
            const year = date.getFullYear();
            group[year] = group[year] ?? [];
            group[year].push(product);
            return group;
          }, {});
          let final = [];
          Object.keys(grouped).forEach(function (key) {
            grouped[key] = grouped[key].sort((a, b) => {
              return a.index - b.index;
            });
            final.push({
              year: key,
              slides: grouped[key],
            });
          });
          final.forEach((item) => {
            item.slides.sort((a,b)=> {
              return b.time_begin - a.time_begin
            })
          });
          topicData.value = final.sort((a, b) => {
            return b.year - a.year;
          });
          console.log(topicData.value);

          final.forEach((item) => {
            flatSlides.value.push(...item.slides);
          });

          flatSlides.value.forEach((item, index) => {
            item.tab_index = index;
          });
          const secondGrouped = flatSlides.value.reduce((group, product) => {
            const { time_begin } = product;
            const date = new Date(time_begin * 1000);
            const year = date.getFullYear();
            group[year] = group[year] ?? [];
            group[year].push(product);
            return group;
          }, {});
          let second_final = [];
          Object.keys(secondGrouped).forEach(function (key) {
            second_final.push({
              year: key,
              slides: secondGrouped[key],
            });
          });

          topicData.value = second_final.sort((a, b) => {
            return b.year - a.year;
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    const onSlideChange = (e) => {
      const activeIndex = e.activeIndex + 1;
      const moveX = activeIndex == 1 ? 0 : activeIndex * 100;
      const finalX =
        moveX > pag.value.clientWidth - pagination.value.clientWidth
          ? pag.value.clientWidth - pagination.value.clientWidth
          : moveX;
      activeTab.value = activeIndex;
      translateX.value = {
        transform: "translateX(" + -finalX + "px)",
      };
    };
    return {
      swiperOption,
      onSlideChange,
      slider,
      onSwiper,
      slides,
      flatSlides,
      activeTab,
      translateX,
      pag,
      pagination,
      isLoad,
      topicData,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    Loader,
  },
};
</script>

<style lang="scss" scoped>
@import url("./index.scss");
</style>
