<template>
  <TPDefault />
</template>
<script>
import { onMounted } from "@vue/runtime-core";
import business from "./store/business";
import { useStore } from "vuex";
import TPDefault from "@/views/TPdefault.vue";
export default {
  name: "app",
  setup() {
    const store = useStore();
    store.dispatch("getWebToken");

    const dispatch = () => {
      business.reStoreOCSSscript();
    };

    onMounted(() => {
      dispatch();
    });
  },
  components: {
    TPDefault,
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#ukefu-point {
  display: none !important;
}
</style>
