<template>
  <div class="manual-container opacity-in">
    <div class="_swiper_cont">
      <swiper
        :options="swiperOption"
        class="swiper"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide v-for="(image, index) in images" :key="index">
          <div
            class="img-slide"
            :style="{ backgroundImage: 'url(' + image.pic + ')' }"
          ></div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="paginations">
      <div class="prev btn" @click="slider.slidePrev()">
        <img src="../../assets/Images/left.svg" alt="" />
      </div>
      <div class="pagination">{{ counter }}/{{ length }}</div>
      <div class="next btn" @click="slider.slideNext()">
        <img src="../../assets/Images/right.svg" alt="" />
      </div>
    </div>
    <Loader v-if="isLoad" />
  </div>
</template>

<script>
import { Swiper, SwiperSlide, useSwiper } from "swiper/vue";
import "swiper/css";
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import axios from "axios";
import Loader from "@/components/loader/index.vue";

export default {
  setup() {
    //refs
    const swiperOption = ref({
      slidesPerView: "1",
    });
    const counter = ref(1);
    const slider = ref();
    const images = ref();
    const isLoad = ref(false);
    const length = ref();

    //mounts
    onMounted(() => {
      getImages();
    });

    //methods
    const onSwiper = (swiper) => {
      slider.value = swiper;
    };
    const onSlideChange = (e) => {
      counter.value = e.activeIndex + 1;
    };
    const getImages = async () => {
      isLoad.value = true;
      try {
        const wmsPath =
          location.origin.includes("a01-brandsite.k8s-fat") ||
          location.origin.includes("localhost")
            ? "_t"
            : "_l";
        const response = await axios.get(
          `cdn/1e3c3bPB/externals/static/_wms/${wmsPath}/_data/_promo/manual_data.txt?` +
            Math.floor(Date.now() / 1000)
        );
        if (response) {
          isLoad.value = false;
          images.value = response.data.sort((a,b) => {
            return a.index - b.index; 
          });
          length.value = images.value.length;
        }
      } catch (error) {
        console.log(error);
      }
    };
    return {
      swiperOption,
      onSlideChange,
      counter,
      slider,
      onSwiper,
      images,
      length,
      isLoad,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    Loader,
  },
};
</script>

<style lang="scss" scoped>
@import url("./index.scss");
</style>
