<template>
  <div class="container opacity-in">
    <div class="content">
      <div class="title">品牌故事</div>
      <div class="paragraph">
        918.com是一家致力于提供高品质娱乐体验平台，集旅游产品及娱乐体验为一体，聚集了一群高素质的团队，包括旅游专家、地理学家，酒店管理人员和旅游规划师等，体验产品涵盖在线娱乐、文化遗产、生态探险、美食之旅等娱乐体验，每个产品都经过精心设计与策划，力求让客户获得更好的体验；<br />918.com强调产品的高品质、专业化、定制化服务，918.com不仅仅是一个旅游品牌，更是专注于娱乐体验研发平台。
      </div>
    </div>
    <div class="img-group">
      <div
        class="img"
        v-for="(storyData, index) in storyDatas"
        :key="index"
        :style="storyData.bg"
      >
        <div class="sub-title">{{ storyData.title }}</div>
        <div class="para">{{ storyData.para }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  setup() {
    const storyDatas = ref([
      {
        title: "在线娱乐",
        para: "918.com在线娱乐，竭力打造 全球化顶尖娱乐体验，领娱乐 平台诚信/专业化。",
        bg: {
          background: "url(" + require("./img/1.png") + ") no-repeat center 0",
        },
      },
      {
        title: "文化遗产",
        para: " 918.com探寻、传承与维护非物质文化遗产，以我们的认知，展现中国传统文化/知识与传承保护，作为历史的见证和民族的感情纽带，让文化遗产再次焕发崭新的生命。",
        bg: {
          background: "url(" + require("./img/2.png") + ") no-repeat center 0",
        },
      },
      {
        title: "生态探险",
        para: "918.com探险大自然的同时，也欣然感悟那份人与自然的和谐相处之道，通过安全有保障的自然探险，猎奇大自然所赋予的美景和冒险项目，展开与内心的深刻对话。",
        bg: {
          background: "url(" + require("./img/3.png") + ") no-repeat center 0",
        },
      },
      {
        title: "美食之旅",
        para: "918.com寻味之旅，是新晋的一种旅行方式，美食的无尽价值，蕴含着历史、社会和人文本身，体验最初的味蕾，人间烟火味，最抚凡人心。",
        bg: {
          background: "url(" + require("./img/4.png") + ") no-repeat center 0",
        },
      },
      {
        title: "旅游之行",
        para: " 918.com融合全球旅游资源整合，文旅融合，产品开发，品牌打造等方面，以专业和异样的角度，观看不一样的世界。",
        bg: {
          background: "url(" + require("./img/5.png") + ") no-repeat center 0",
        },
      },
    ]);
    return {
      storyDatas,
    };
  },
};
</script>

<style lang="scss" scoped>
@import url("./index.scss");
</style>
