import { createRouter, createWebHistory } from "vue-router";
import home from "../views/news/index.vue";
import story from "../views/story/index.vue";
import topic from "../views/topics/index.vue";
import culture from "../views/culture/index.vue";
import manual from "../views/manual/index.vue";
import gift from "../views/gift/index.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: home,
  },
  {
    path: "/story",
    name: "story",
    component: story,
  },
  {
    path: "/topic",
    name: "topic",
    component: topic,
  },
  {
    path: "/culture",
    name: "culture",
    component: culture,
  },
  {
    path: "/manual",
    name: "manual",
    component: manual,
  },
  {
    path: "/gift",
    name: "gift",
    component: gift,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
    component: home,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
