<template>
  <div class="gift-container">
    <div class="collection">
      <img src="./imgs/collection.png" alt="" draggable="false" />
      <a href="https://biaodan100.com/q/lfokyi">领取礼品</a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.gift-container {
  background: url("./imgs/bg.png") no-repeat center 0;
  height: 91.5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  .collection {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    img {
      max-width: 100%;
      height: auto;
    }
    a {
      width: 218px;
      height: 50px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      font-weight: bold;
      border-radius: 5px;
      font-size: 16px;
      color: var(--main-brown);
      box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.15);
      transition: 0.2s;
      &:hover {
        scale: 1.03;
      }
    }
  }
}
</style>
