<template>
  <div class="nav-bar">
    <div class="logo" @click="goHome">
      <img src="./img/logo.svg" alt="" />
    </div>
    <div class="links">
      <router-link class="link" to="/" @click="hideDetail"
        >品牌资讯</router-link
      >
      <router-link class="link" to="/story">品牌故事</router-link>
      <router-link class="link" to="/culture">品牌文化</router-link>
      <router-link class="link" to="/topic">品牌专题</router-link>
      <router-link class="link" to="/gift">礼品领取</router-link>
      <!-- <router-link class="link" to="/manual">品牌手册</router-link> -->
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import router from "@/router";
export default {
  setup() {
    //refs
    const store = useStore();

    //methods;
    const hideDetail = () => {
      store.commit("SET_SHOW_DETAIL", false);
    };
    const goHome = () => {
      store.commit("SET_SHOW_DETAIL", false);
      router.push("/");
    };
    return {
      hideDetail,
      goHome,
    };
  },
};
</script>

<style lang="scss" scoped>
.nav-bar {
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0px 120px;
  height: 8.5vh;
  align-items: center;
  .logo {
    img {
      cursor: pointer;
    }
  }
  .links {
    display: flex;
    gap: 92px;
    align-items: center;

    .link {
      color: #949494;
      font-size: 16px;
      text-decoration: none;
      font-weight: 700;
      white-space: nowrap;
      &:hover {
        color: #9d7652;
      }
    }
    a {
      &.router-link-active {
        color: #9d7652;
      }
    }
  }
}
</style>
