<template>
  <div class="news-container opacity-in">
    <div class="btn-group">
      <button
        class="btn"
        :class="{ btnActive: isBasic }"
        @click="changeTab('basic_info', true)"
      >
        旅游资讯
      </button>
      <button
        class="btn"
        :class="{ btnActive: !isBasic }"
        @click="changeTab('exclusive_info', false)"
      >
        专属资讯
      </button>
    </div>
    <div class="slider">
      <div class="left arrow" @click="leftClick">
        <img src="./imgs/left.svg" alt="" />
      </div>
      <div
        class="inner-slider"
        ref="slider"
        @mousedown="down"
        @mouseleave="leave"
        @mouseup="up"
        @mousemove="move"
      >
        <div class="row-one">
          <div class="big-card" @click="goToDetails(firstData)">
            <img :src="firstImage" alt="" class="big-img" />
          </div>
          <div class="desc">
            <div>
              <div class="title">{{ firstImageTitle }}</div>
              <div class="sub-title">
                {{ firstImageDesc }}
              </div>
            </div>
            <div class="preview" @click="goToDetails(firstData)">
              查看详情
              <img src="./imgs/continue.svg" alt="" class="cont" />
            </div>
          </div>
        </div>
        <div class="row-two">
          <div class="col">
            <div
              class="small-card"
              v-for="(image, index) in images"
              :key="index"
              @click="goToDetails(image)"
            >
              <img :src="image.pic" alt="" class="img" />
              <div class="card-title">{{ image.title }}</div>
            </div>
          </div>
          <!-- <div class="col">
            <div
              class="small-card"
              v-for="(image, index) in rowTwo"
              :key="index"
              @click="goToDetails(image)"
            >
              <img :src="image.pic" alt="" class="img" />
              <div class="card-title">{{ image.title }}</div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="right arrow" @click="rightClick">
        <img src="./imgs/right.svg" alt="" />
      </div>
    </div>
  </div>
  <Details v-if="isShowDetail" :image="image" @back="goBack" />
  <Loader v-if="isLoad" />
</template>

<script>
import { ref } from "@vue/reactivity";
import { computed, onMounted, onUnmounted } from "@vue/runtime-core";
import axios from "axios";
import Details from "./component/details.vue";
import { useStore } from "vuex";
import Loader from "@/components/loader/index.vue";

export default {
  setup() {
    //refs
    const images = ref([]);
    const firstImage = ref();
    const slider = ref(null);
    const details = ref(false);
    const image = ref();
    const rowOne = ref([]);
    const rowTwo = ref([]);
    const isDown = ref(false);
    const startX = ref();
    const scrollLeft = ref();
    const isBasic = ref(true);
    const startTime = ref();
    const elapsedTime = ref();
    const store = useStore();
    const isScroll = ref();
    const firstImageTitle = ref();
    const firstImageDesc = ref();
    const firstData = ref();
    const isLoad = ref(false);
    //mounts
    onMounted(() => {
      slider.value.style = "scroll-behavior: smooth;";
      getImages("basic_info");
    });
    //computed
    const isShowDetail = ref(computed(() => store.state.showDetail));

    //methods
    const getImages = async (tab) => {
      isLoad.value = true;
      try {
        const wmsPath =
          location.origin.includes("a01-brandsite.k8s-fat") ||
          location.origin.includes("localhost")
            ? "_t"
            : "_l";
        const response = await axios.get(
          `cdn/1e3c3bPB/externals/static/_wms/${wmsPath}/_data/_promo/${tab}.txt?` +
            Math.floor(Date.now() / 1000)
        );
        if (response) {
          isLoad.value = false;
        }
        const finalData = response.data.sort((a, b) => {
          return a.index - b.index;
        });
        const divisor = finalData.length / 2;
        rowOne.value = finalData.slice(1, divisor + 1);
        rowTwo.value = finalData.slice(divisor + 1, finalData.length);
        images.value = finalData.slice(1, finalData.length);
        firstImage.value = finalData[0].pic;
        firstImageTitle.value = finalData[0].title;
        const firstDesc = finalData[0].describe;
        firstImageDesc.value =
          firstDesc.length > 34 ? firstDesc.slice(0, 34) + "..." : firstDesc;

        firstData.value = finalData[0];
      } catch (error) {
        console.log(error);
      }
    };
    const down = (e) => {
      isDown.value = true;
      startX.value = e.pageX + 15 - slider.value.offsetLeft;
      scrollLeft.value = slider.value.scrollLeft;
      slider.value.style = "scroll-behavior: none;";
      startTime.value = new Date();
    };
    const leave = () => {
      isDown.value = false;
    };
    const up = (e) => {
      isDown.value = false;
      slider.value.style = "scroll-behavior: smooth;";
      let current = new Date();
      elapsedTime.value = current - startTime.value;
    };
    const move = (e) => {
      if (!isDown.value) return;
      e.preventDefault();
      const x = e.pageX - slider.value.offsetLeft;
      const walk = x - startX.value;
      slider.value.scrollLeft = scrollLeft.value - walk;
      isScroll.value = e;
    };
    const changeTab = (tab, condition) => {
      isBasic.value = condition;
      getImages(tab);
    };
    const leftClick = () => {
      const moveX =
        slider.value.clientWidth / 2 - slider.value.scrollLeft + 860;
      slider.value.scrollLeft = -moveX;
    };
    const rightClick = () => {
      const moveX =
        slider.value.clientWidth / 2 + slider.value.scrollLeft + 860;
      slider.value.scrollLeft = moveX;
    };
    const goToDetails = (img) => {
      if (elapsedTime.value > 150) return;
      store.commit("SET_SHOW_DETAIL", true);
      image.value = img;
    };
    const goBack = () => {
      store.commit("SET_SHOW_DETAIL", false);
      isScroll.value = 0;
      slider.value.style = "scroll-behavior: smooth;";
    };

    return {
      slider,
      images,
      firstImage,
      leftClick,
      rightClick,
      details,
      image,
      goToDetails,
      rowOne,
      rowTwo,
      down,
      leave,
      up,
      move,
      isDown,
      startX,
      scrollLeft,
      isBasic,
      goBack,
      isShowDetail,
      firstImageTitle,
      firstImageDesc,
      changeTab,
      isLoad,
      firstData,
    };
  },
  components: {
    Details,
    Loader,
  },
};
</script>

<style lang="scss" scoped>
@import url("./index.scss");
</style>
